<template>
  <div>
    <Modal modal-class="modal-import slide-up" ref="modal_import" size="md" :on-hide="onHideImportModal"
           :modal-header-title="$t('taxi.import fee rule')">
      <template v-slot:container>
        <ValidationObserver ref="form_import" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(importUpload)">
            <div class="modal-body">
              <span class="app-text">
                CSVに登録されている全ての料金プランが追加（新規作成）されます。
                既存のプランを変更（削除）する機能はありません。
              </span>
              <HeaderNotice ref="notice"></HeaderNotice>
              <ValidationProvider name="file_import" v-slot="{ errors, invalid, validated }">
                <div :class="['custom-file form-group', invalid && validated ? 'has-error' : '']">
                  <input @change="changeFileImport" type="file" ref="file_import" class="custom-file-input"
                         id="customFile">
                  <span class="custom-file-label" for="customFile">{{ importData.fileName }}</span>
                  <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
                </div>
              </ValidationProvider>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button v-if="importData.file" :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                全て追加（新規作成）
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('taxi.create fee rule')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <HeaderNotice ref="date_range_error_create">
                <template v-slot:error>
                  <div>既に登録された期間があります。(
                    <router-link
                      :to="{
                        name: $consts.ROUTES.TAXI.TAXI_FEE_RULE,
                        query: {
                          'filters.start_date.in_date_range_start': entryToSave.start_date,
                          'filters.end_date.in_date_range_end': entryToSave.end_date,
                          'filters.start_point_id.equal': entryToSave.start_point_id,
                          'filters.taxi_type_id.equal': entryToSave.taxi_type_id,
                          'filters.hotel_id.equal': entryToSave.hotel_id
                        }
                      }" target="_blank">こちら
                    </router-link>
                    をご確認ください）
                  </div>
                </template>
              </HeaderNotice>
              <AppSelect :options="{placeholder: $t('common.taxi_type')}" name="taxi_type"
                         :label="$t('common.taxi_type')" rules="required"
                         :options-data="taxiTypes"
                         v-model="entryToSave.taxi_type_id">
              </AppSelect>
              <AppSelect :options="{placeholder: $t('common.start_point')}" name="start_point"
                         :label="$t('common.start_point')" rules="required"
                         :options-data="startPoints"
                         v-model="entryToSave.start_point_id">
              </AppSelect>
              <AppSelect :options="{placeholder: $t('taxi.end_point')}" name="end_point"
                         :label="$t('taxi.end_point')" rules="required"
                         :options-data="hotels"
                         v-model="entryToSave.hotel_id">
              </AppSelect>
              <AppDatePicker name="start_date" :label="$t('common.start_date')" rules="required"
                             v-model="entryToSave.start_date"/>
              <AppDatePicker name="end_date" :label="$t('common.end_date')" rules="required|after_eq_date:@start_date"
                             v-model="entryToSave.end_date"/>
              <AppInput name="name" :label="$t('taxi.plan name')" type="string" rules="required"
                        v-model="entryToSave.name"/>
              <AppInput type="number-length:8" rules="required" name="price" :label="$t('common.price')"
                        v-model="entryToSave.price"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('taxi.create fee rule')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <HeaderNotice ref="date_range_error_update">
                <template v-slot:error>
                  <div>既に登録された期間があります。(
                    <router-link
                      :to="{
                        name: $consts.ROUTES.TAXI.TAXI_FEE_RULE,
                        query: {
                          'filters.start_date.in_date_range_start': entryToEdit.start_date,
                          'filters.end_date.in_date_range_end': entryToEdit.end_date,
                          'filters.start_point_id.equal': entryToEdit.start_point_id,
                          'filters.taxi_type_id.equal': entryToEdit.taxi_type_id,
                          'filters.hotel_id.equal': entryToEdit.hotel_id,
                          'filters.id.not_equal': entryToEdit.id
                        }
                      }" target="_blank">こちら
                    </router-link>
                    をご確認ください）
                  </div>
                </template>
              </HeaderNotice>
              <AppSelect :options="{placeholder: $t('common.taxi_type')}" name="taxi_type"
                         :label="$t('common.taxi_type')" rules="required"
                         :options-data="taxiTypes"
                         v-model="entryToEdit.taxi_type_id">
              </AppSelect>
              <AppSelect :options="{placeholder: $t('common.start_point')}" name="start_point"
                         :label="$t('common.start_point')" rules="required"
                         :options-data="startPoints"
                         v-model="entryToEdit.start_point_id">
              </AppSelect>
              <AppSelect :options="{placeholder: $t('taxi.end_point')}" name="end_point"
                         :label="$t('taxi.end_point')" rules="required"
                         :options-data="hotels"
                         v-model="entryToEdit.hotel_id">
              </AppSelect>
              <AppDatePicker name="start_date" :label="$t('common.start_date')" rules="required"
                             v-model="entryToEdit.start_date"/>
              <AppDatePicker name="end_date" :label="$t('common.end_date')" rules="required|after_eq_date:@start_date"
                             v-model="entryToEdit.end_date"/>
              <AppInput name="name" :label="$t('taxi.plan name')" type="string" rules="required"
                        v-model="entryToEdit.name"/>
              <AppInput type="number-length:8" rules="required" name="price" :label="$t('common.price')"
                        v-model="entryToEdit.price"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('taxi.taxi fee rule'),
      apis: {
        list: {
          endpoint: this.$consts.API.TAXI.TAXI_FEE_RULE_LIST,
        },
        delete: {
          endpoint: this.$consts.API.TAXI.TAXI_FEE_RULE_DELETE,
        }
      },
      actions: {
        createEntry: this.createEntry,
        importEntries: this.importEntries,
        exportEntries: this.exportEntries,
        others: [
          {
            label: 'コピー',
            click: entry => this.editEntry(entry),
            contentHtml: entry => `<div class="btn-tool default text-center">コピー</div>`,
          },
          {
            label: this.$t('common.delete'),
            click: entry => this.$refs.table.deleteEntry(entry),
            contentHtml: entry => `<div class="btn-tool delete"></div>`,
          }
        ]
      },
      tools: {
        search: {
          // reset: true,
          filters: [
            {
              name: 'id',
              type: 'not_equal',
            },
            {
              name: 'start_date',
              rules: 'required_if:end_date',
              type: 'in_date_range_start',
              label: this.$t('common.start_date'),
              // uiType: 'datepicker',
            },
            {
              name: 'end_date',
              rules: 'required_if:start_date',
              type: 'in_date_range_end',
              label: this.$t('common.end_date'),
              // uiType: 'datepicker',
            },
            {
              name: 'taxi_type_id',
              type: 'equal',
              label: this.$t('common.taxi_type'),
              // uiType: 'select',
            },
            {
              name: 'start_point_id',
              type: 'equal',
              label: this.$t('common.start_point'),
              // uiType: 'select',
            },
            {
              name: 'hotel_id',
              type: 'equal',
              label: this.$t('taxi.end_point'),
              // uiType: 'select',
            }
          ]
        }
      },
      importData: {
        file: null,
        fileName: null
      },
      entrySelectedDelete: {},
      entryToSave: {},
      entryToEdit: {},
      taxi: {},
      taxiTypes: [],
      startPoints: [],
      hotels: [],
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'name', label: this.$t('taxi.plan name'), sortable: false},
        {name: 'start_date', label: this.$t('common.start_date'), computed: entry => this.formatDate(entry.start_date)},
        {name: 'end_date', label: this.$t('common.end_date'), computed: entry => this.formatDate(entry.end_date)},
        {name: 'taxi_type.name', label: this.$t('common.taxi_type'), sortable: false},
        {name: 'start_point.name', label: this.$t('common.start_point'), sortable: false},
        {name: 'hotel.name', label: this.$t('taxi.end_point'), sortable: false},
        {
          name: 'price',
          label: this.$t('common.price'),
          sortable: true,
          computed: entry => this.formatPriceJa(entry.price)
        },
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.$request.get(this.$consts.API.TAXI.TAXI_COMPANY_SHOW, null, {
      noLoadingEffect: true,
      handleNotFound: true
    }).then(res => {
      if (!res.hasErrors()) {
        this.taxi = res.data.data
        this.$store.commit('setting/updateBreadCrumb', {taxi_company: this.taxi.name})
        this.$request.get(this.$consts.API.TAXI.TAXI_TYPE_SELECT).then(res => {
          if (!res.hasErrors()) {
            this.taxiTypes = res.data.data
            // this.setOptionFilter(this.tools, 'taxi_type_id', 'equal', this.taxiTypes);
          }
        })
        this.$request.get(this.$consts.API.TAXI.START_POINT_LIST_SELECT, null).then(res => {
          if (!res.hasErrors()) {
            this.startPoints = res.data.data
            // this.setOptionFilter(this.tools, 'start_point_id', 'equal', this.startPoints);
          }
        })
        this.$request.get(this.$consts.API.TAXI.HOTEL_LIST_SELECT, null).then(res => {
          if (!res.hasErrors()) {
            this.hotels = res.data.data
            // this.setOptionFilter(this.tools, 'hotel_id', 'equal', this.hotels);
          }
        })
      }
    })
  },
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {}
      this.$refs.form_create_entry.reset()
      this.$refs.date_range_error_create.clear();
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {taxi_type_id, start_point_id, hotel_id, start_date, end_date, price, name} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.TAXI.TAXI_FEE_RULE_SAVE, {
        taxi_type_id, start_point_id, hotel_id, start_date, end_date, price, name
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else if (res.data?.date_error) {
          this.$refs.date_range_error_create.setError();
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      // Method này là create from copy, ko phải update, nhưng lợi dụng code từ phần update làm cho nhanh
      const {taxi_type_id, start_point_id, hotel_id, start_date, end_date, price, name} = this.entryToEdit
      const res = await this.$request.post(this.$consts.API.TAXI.TAXI_FEE_RULE_SAVE, {
        taxi_type_id, start_point_id, hotel_id, start_date, end_date, price, name
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else if (res.data?.date_error) {
          this.$refs.date_range_error_update.setError();
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
      this.$refs.date_range_error_update.clear();
    },
    /* UPDATE entry end */
    importEntries() {
      this.$refs.modal_import.show();
    },
    exportEntries() {
      this.$request.get(this.$consts.API.ADMIN.DOWNLOAD_CSV_TAXI_FEE_RULE).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error()
        }
      })
    },
    changeFileImport() {
      const file = this.$refs.file_import.files[0]
      console.log('import with file type: ' + file.type)
      if (file && (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
        this.importData = {
          file, fileName: file.name
        }
        this.$refs.notice.clear()
        this.$refs.form_import.reset();
      } else {
        this.$refs.form_import.setErrors({
          file_import: [
            this.$t('common.file ext err')
          ]
        })
      }
    },
    importUpload() {
      const file = this.importData.file
      let formData = new FormData
      formData.append('file', file)
      formData.append('taxi_company_id', this.taxi.id)
      this.$request.post(this.$consts.API.ADMIN.IMPORT_TAXI_FEE_RULE, formData).then(res => {
        if (!res.hasErrors()) {
          this.$refs.notice.clear();
          this.$refs.modal_import.hide();
          this.$appNotice.success(this.$t('common.import success'))
          this.$refs.table.getEntries()
        } else {
          try {
            this.$refs.notice.setError(res.data[0].errors[0])
          } catch (e) {
            this.$appNotice.error(this.$t('common.import err'))
          }
        }
        this.$refs.form_import.reset();
        this.resetImportForm();
      })
    },
    onHideImportModal() {
      this.resetImportForm();
      this.$refs.notice.clear()
    },
    resetImportForm() {
      this.importData = {
        file: null,
        fileName: null
      }
      this.$refs.form_import.reset();
      $(this.$refs.file_import).val('');
    }
  }
}
</script>
